import { DataTable, Pagination } from "../../../components/table/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import {
  Box,
  Center,
  Text,
  Flex,
  useColorModeValue,
  Badge,
  Tag,
  TagLabel,
  Button,
  TagRightIcon,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import NetworkWrapper from "../../../components/wrapper/NetworkWrapper";

import { Icon } from "@iconify/react";
import { getTimeDiff } from "../../../utils";
import { Header } from "../../../components/table/CaseListingTableComponent";
import { useDaasApi } from "../../../hooks/api/useDaasApi";
import {
  AMBULANCE_TYPE_COLORS,
  AMBULANCE_TYPE_ICON,
  PERSONNEL_LIST_STATUS_THEME,
} from "../../../config/constant";
import { useNavigate } from "react-router-dom";
import { useAppLatestVersion } from "../../../hooks/api/useAppApi";
import { useEffect, useState } from "react";

export default function PersonnelList() {
  const {
    finalData,
    multipleEntitiesById,
    multipleEntitiesByIdFetching,
    shiftFetching,
    multipleEntitiesFetching,
  } = useDaasApi();
  const { data: appData }: any = useAppLatestVersion();

  const [analytics, setAnalytics] = useState<{ [key: string]: any }>({});
  const navigate = useNavigate();

  const columnHelper = createColumnHelper<any>();
  const themeFont = useColorModeValue("slate-800", "white");
  const themeBg = useColorModeValue("white", "slate.800");

  const columns = [
    columnHelper.accessor("registration_number", {
      cell: (info) => (
        <Tag colorScheme="blue">
          <TagLabel>
            <Text fontWeight={"semibold"}>{info.getValue()}</Text>
          </TagLabel>
        </Tag>
      ),
      header: () => <Header text={"Ambulance No"} />,
    }),
    columnHelper.accessor("fleet_type", {
      cell: (info) => <AmbTypeRender type={info.getValue()} />,
      header: () => <Header text={"Amb Type"} />,
    }),

    columnHelper.accessor("pilot_name", {
      cell: (info) => (
        <Flex direction={"column"}>
          <Flex align={"center"} mb={2}>
            <Icon icon={"mdi:account-pilot"} fontSize={16} />
            <Text
              ml={1}
              whiteSpace={"nowrap"}
              fontSize={"xs"}
              fontWeight={"semibold"}
            >
              {info.getValue()}
            </Text>
          </Flex>
          <Flex align={"center"}>
            <Icon icon={"ph:phone"} fontSize={14} />
            <Text ml={2} fontSize={"xs"}>
              {info.row.original?.pilot_phone}
            </Text>
          </Flex>
        </Flex>
      ),
      header: () => <Header text={"Pilot"} />,
    }),
    columnHelper.accessor("paramedic_name", {
      cell: (info) => (
        <Flex direction={"column"}>
          {info.getValue() && (
            <Flex
              color={themeFont}
              align={"center"}
              mb={2}
              fontWeight={"semibold"}
            >
              <Icon icon={"mdi:doctor"} fontSize={16} />
              <Text ml={2} whiteSpace={"nowrap"} fontSize={"xs"}>
                {info.getValue()}
              </Text>
            </Flex>
          )}
          {info.row.original?.paramedic_phone && (
            <Flex color={themeFont}>
              <Icon icon={"ph:phone"} fontSize={14} />
              <Text ml={2} fontSize={"xs"}>
                {info.row.original?.paramedic_phone}
              </Text>
            </Flex>
          )}
        </Flex>
      ),
      header: () => <Header text={"Paramedic"} />,
    }),
    columnHelper.accessor("entityDataIdRef", {
      cell: (info) => (
        <RenderStatus
          entityDataIdRef={info.getValue()}
          entityDataRef={info.row.original.entityDataRef}
        />
      ),
      header: () => <Header text={"Status"} />,
    }),
    columnHelper.accessor(
      "entityDataIdRef.meta.assignmentDetails.clientRequestId",
      {
        cell: (info) =>
          info.getValue() ? (
            <Tag
              colorScheme="primary"
              _hover={{ cursor: "pointer", shadow: "md" }}
              onClick={() => navigate(`/case/${info.getValue()}`)}
            >
              <TagLabel>
                <Text fontWeight={"semibold"}>{info.getValue()}</Text>
              </TagLabel>
              <TagRightIcon as={RightIcon} />
            </Tag>
          ) : (
            "-"
          ),
        header: () => <Header text={"Last Assigned To"} />,
      }
    ),
    columnHelper.accessor("city", {
      cell: (info) => (
        <Badge borderRadius={"full"} px={3} colorScheme="primary">
          <Text>{info.getValue()}</Text>
        </Badge>
      ),
      header: () => <Header text={"🌆 City"} />,
    }),
    columnHelper.accessor("is_dedicated", {
      cell: (info) => (
        <Text fontWeight={"semibold"}>
          {info.getValue() ? "Dedicated" : "Independent"}
        </Text>
      ),
      header: () => <Header text={"Dedicated/Independent"} />,
    }),
    columnHelper.accessor("pilot_id", {
      cell: (info) => (
        <Flex wrap={"nowrap"}>
          <Text ml={3}>{info.getValue()}</Text>
        </Flex>
      ),
      header: () => <Header text={"*️⃣ Employee Id"} />,
    }),
    columnHelper.accessor("branch_name", {
      cell: (info) => (
        <Text color="red" fontWeight={"normal"}>
          {info.getValue()}
        </Text>
      ),
      header: () => <Header text={"Dedicated To"} />,
    }),
    columnHelper.accessor("entityDataIdRef.meta.deviceInfo.appVersion", {
      cell: (info) => (
        <VersionRender
          latestVersion={appData?.data?.data?.meta?.appVersion}
          userVersion={info.getValue()}
        />
      ),
      header: () => <Header text={"App Version"} />,
    }),

    // columnHelper.accessor("pilot_id", {
    //   cell: (info) => (
    //     <Button
    //       disabled={!info.getValue()}
    //       colorScheme="primary"
    //       variant={"outline"}
    //       size={"sm"}
    //       fontWeight={"semibold"}
    //       onClick={() => navigate("/fleet-tracing", { state: info.getValue() })}
    //     >
    //       <Flex align={"center"}>
    //         <Icon icon={"icon-park-outline:trace"} fontSize={20} />
    //         <Box ml={2}>Fleet Tracing</Box>
    //       </Flex>
    //     </Button>
    //   ),
    //   header: () => <Header text={"Tracing"} />,
    // }),
  ];

  useEffect(() => {
    const arr = multipleEntitiesById?.data?.data || [];
    if (arr?.length > 0) {
      const ar = arr.map(getStatusObj);
      const obj: { [key: string]: any } = { total: ar?.length };

      ar?.forEach((itm: any) => {
        const label = itm.label;
        if (!obj[label]) {
          obj[label] = 1;
        } else {
          obj[label] = obj[label] + 1;
        }
      });

      setAnalytics(obj);
    }
  }, [multipleEntitiesById]);

  return (
    <Center h={"100vh"}>
      <NetworkWrapper>
        <Box maxW={["95vw", "80vw"]} w={"full"} overflow={"hidden"}>
          {/* {filters && (
            <Box zIndex={200} bg={'white'} w="full" mt={[20, 'auto']} mb={[4, 'auto']}>
              <TableHeader
                id="queryString"
                valueOfId={getParams('queryString') || ''}
                filters={filters}
              />
            </Box>
          )} */}
          <Box
            zIndex={200}
            bg={themeBg}
            w="full"
            mt={[20, "auto"]}
            mb={4}
            borderRadius={"md"}
          >
            <Wrap p={4}>
              {Object.keys(analytics)?.map((itm) => (
                <WrapItem key={itm} mr={2}>
                  <Tag colorScheme={PERSONNEL_LIST_STATUS_THEME[itm]?.color}>
                    <TagLabel
                      textTransform={"uppercase"}
                      fontSize={"xs"}
                      fontWeight={"semibold"}
                    >
                      {itm}: {analytics[itm]}
                    </TagLabel>
                  </Tag>
                </WrapItem>
              ))}
            </Wrap>
          </Box>
          <Box
            borderRadius={"md"}
            maxH={["90vh", "85vh"]}
            maxW={["95vw", "80vw"]}
            overflow={"scroll"}
            bg={"white"}
            shadow={"2xl"}
            className="custom-scrollbar"
          >
            {finalData && finalData?.length > 0 && (
              <DataTable
                isDataLoading={
                  shiftFetching.isLoading ||
                  multipleEntitiesFetching.isLoading ||
                  multipleEntitiesByIdFetching.isLoading
                }
                columns={columns}
                data={finalData}
              />
            )}
          </Box>
        </Box>
        {/* <Box my={5}>
          <Pagination
            currentPage={meta.pageIndex || 0}
            totalPages={meta.pageCount}
            maxVisiblePages={meta.pageCount > 3 ? 5 : 3}
            onPageChange={setPage}
          />
        </Box> */}
      </NetworkWrapper>
    </Center>
  );
}

const VersionRender = ({
  userVersion,
  latestVersion,
}: {
  userVersion: string;
  latestVersion: string;
}) => {
  const isOutdatedUserVersion = !userVersion?.includes(latestVersion);
  const colorScheme = isOutdatedUserVersion ? "red" : "green";

  return userVersion ? (
    <Box>
      <Badge colorScheme={colorScheme} px={2} py={1} mb={2}>
        <Text fontWeight={"semibold"}>{userVersion}</Text>
      </Badge>
      {isOutdatedUserVersion && (
        <Text color={"red"}>
          Please update to latest version:
          {latestVersion}
        </Text>
      )}
    </Box>
  ) : (
    "-"
  );
};

function getStatusObj(entityDataIdRef: any) {
  let status = "ONLINE";
  if (!entityDataIdRef?.availability) {
    status = entityDataIdRef?.reason === "ON_TRIP" ? "ON_TRIP" : "OFFLINE";
  }
  return PERSONNEL_LIST_STATUS_THEME[status];
}

const RenderStatus = ({ entityDataIdRef, entityDataRef }: any) => {
  const lastUpdated = getTimeDiff(entityDataIdRef?.updatedAt) + " ago";
  const lastPing = `Last ping ${getTimeDiff(
    Number(entityDataRef.timestamp),
    true
  )} ago`;

  const status = getStatusObj(entityDataIdRef);

  return !entityDataIdRef ? (
    <></>
  ) : (
    <Box minW={100}>
      <Tag colorScheme={status.color} rounded={"full"}>
        <TagLabel color={status.color} fontWeight={"semibold"} fontSize={"xs"}>
          {status.label}
        </TagLabel>
      </Tag>
      <Text fontSize={"xs"} my={2} color={status.color}>
        {lastUpdated}
      </Text>
      <Text fontSize={"xs"} my={2} color={"red.400"}>
        {lastPing}
      </Text>
    </Box>
  );
};

export const AmbTypeRender = ({ type }: { type: string }) => {
  const defaultColor = "slate.800";
  const transformText = type?.toUpperCase();

  const tagColorScheme = AMBULANCE_TYPE_COLORS[transformText] || defaultColor;
  const tagIcon = AMBULANCE_TYPE_ICON[transformText];

  return (
    <Tag size="sm" colorScheme={tagColorScheme} borderRadius="full">
      <Box mr={1}>
        <Icon icon={tagIcon} fontSize={16} />
      </Box>
      <TagLabel
        fontWeight="600"
        textTransform={"uppercase"}
        letterSpacing={"wide"}
        fontSize="xs"
      >
        {transformText}
      </TagLabel>
    </Tag>
  );
};

export const RightIcon = () => (
  <Icon icon={"eva:diagonal-arrow-right-up-fill"} fontSize={20} />
);
